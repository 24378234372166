///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		> * {
			> .inner {
				$gutter: _size(gutter);

				width: 100%;
				max-width: _size(inner);
				margin: 0 auto;
				padding: 0 $gutter;

				@include breakpoint('<=small') {
					$gutter: _size(gutter) * 0.5;

					padding: 0 $gutter;
				}
			}
		}
	}