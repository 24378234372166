///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///
@use  'sass:color';

/* Type */

	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 16pt;
		font-weight: _font(weight);
		line-height: 1.75;

		@include breakpoint('<=xlarge') {
			font-size: 14pt;
		}

		@include breakpoint('<=large') {
			font-size: 12pt;
		}
	}

	a {
		@include vendor('transition', (
			'border-bottom-color #{_duration(transition)} ease',
			'color #{_duration(transition)} ease'
		));
		text-decoration: none;
		color: _palette(fg);
		border-bottom: dotted 1px color.adjust(_palette(fg), $alpha: -0.5);

		&:hover {
			border-bottom-color: transparent;
			color: _palette(accent1) !important;
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
        color: _palette(fg-type);
	}

	h1 {
		font-size: 2.75em;
		color: _palette(fg-bold);
		font-weight: _font(weight-bold-alt);
		line-height: 1.3;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		letter-spacing: _font(letter-spacing-alt);

		a {
			color: inherit;
		}

		@include breakpoint('<=small') {
			font-size: 2em;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}

		@include breakpoint('<=xxsmall') {
			font-size: 1.75em;
		}
	}

	h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 1) 0;
		text-transform: uppercase;
		letter-spacing: _font(letter-spacing);

		a {
			color: inherit;
		}
	}

	h2 {
		font-size: 1.1em;
	}

	h3 {
		font-size: 1em;
	}

	h4 {
		font-size: 0.8em;
	}

	h5 {
		font-size: 0.8em;
	}

	h6 {
		font-size: 0.8em;
	}

	@include breakpoint('<=medium') {
		h1, h2, h3, h4, h5, h6 {
			br {
				display: none;
			}
		}
	}

	@include breakpoint('<=small') {
		h2 {
			font-size: 1em;
		}

		h3 {
			font-size: 0.8em;
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid (_size(border-width) * 4) _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (calc(_size(element-margin) / 4)) 0 (calc(_size(element-margin) / 4)) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		border: solid _size(border-width) _palette(border);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid _size(border-width) _palette(border);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}
