///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Tiles */

	.tiles {
		$gutter: _size(gutter);
		$duration: 0.5s;
		$ease: 'ease';

		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		postiion: relative;
		margin: ($gutter * -1) 0 0 ($gutter * -1);

		article {
			@include vendor('transition', (
				'transform #{$duration} #{$ease}',
				'opacity #{$duration} #{$ease}'
			));
			position: relative;
			width: calc(#{calc(100% / 3)} - #{$gutter * 1});
			margin: $gutter 0 0 $gutter;

			> .image {
				@include vendor('transition', 'transform #{$duration} #{$ease}');
				position: relative;
				display: block;
				width: 100%;
				border-radius: _size(border-radius);
				overflow: hidden;

				img {
					display: block;
					width: 100%;
				}

				&:before {
					@include vendor('pointer-events', 'none');
					@include vendor('transition', (
						'background-color #{$duration} #{$ease}',
						'opacity #{$duration} #{$ease}'
					));
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 1.0;
					z-index: 1;
					opacity: 0.8;
				}

				&:after {
					@include vendor('pointer-events', 'none');
					@include vendor('transition', 'opacity #{$duration} #{$ease}');
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" preserveAspectRatio="none"><style>line { stroke-width: 0.25px; stroke: #ffffff; }</style><line x1="0" y1="0" x2="100" y2="100" /><line x1="100" y1="0" x2="0" y2="100" /></svg>');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					opacity: 0.25;
					z-index: 2;
				}
			}

			> a {
				@include vendor('display', 'flex');
				@include vendor('flex-direction', 'column');
				@include vendor('align-items', 'center');
				@include vendor('justify-content', 'center');
				@include vendor('transition', (
					'background-color #{$duration} #{$ease}',
					'transform #{$duration} #{$ease}'
				));
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 1em;
				border-radius: _size(border-radius);
				border-bottom: 0;
				color: _palette(fg-accent);
				text-align: center;
				text-decoration: none;
				z-index: 3;

				> :last-child {
					margin: 0;
				}

				&:hover {
					color: _palette(fg-accent) !important;
				}

				h2 {
					margin: 0;
				}

				.content {
					@include vendor('transition', (
						'max-height #{$duration} #{$ease}',
						'opacity #{$duration} #{$ease}'
					));
					width: 100%;
					max-height: 0;
					line-height: 1.5;
					margin-top: 0.35em;
					opacity: 0;

					> :last-child {
						margin-bottom: 0;
					}
				}
			}

			&.style1 {
				> .image:before {
					background-color: _palette(accent1);
				}
			}

			&.style2 {
				> .image:before {
					background-color: _palette(accent2);
				}
			}

			&.style3 {
				> .image:before {
					background-color: _palette(accent3);
				}
			}

			&.style4 {
				> .image:before {
					background-color: _palette(accent4);
				}
			}

			&.style5 {
				> .image:before {
					background-color: _palette(accent5);
				}
			}

			&.style6 {
				> .image:before {
					background-color: _palette(accent6);
				}
			}

			body:not(.is-touch) & {
				&:hover {
					> .image {
						@include vendor('transform', 'scale(1.1)');

						&:before {
							background-color: _palette(bg-accent);
							opacity: 0.35;
						}

						&:after {
							opacity: 0;
						}
					}

					.content {
						max-height: 15em;
						opacity: 1;
					}
				}
			}
		}

		* + & {
			margin-top: _size(element-margin);
		}

		body.is-preload & {
			article {
				@include vendor('transform', 'scale(0.9)');
				opacity: 0;
			}
		}

		body.is-touch & {
			article {
				.content {
					max-height: 15em;
					opacity: 1;
				}
			}
		}

		@include breakpoint('<=large') {
			$gutter: _size(gutter) * 0.5;

			margin: ($gutter * -1) 0 0 ($gutter * -1);

			article {
				width: calc(#{calc(100% / 3)} - #{$gutter * 1});
				margin: $gutter 0 0 $gutter;
			}
		}

		@include breakpoint('<=medium') {
			$gutter: _size(gutter);

			margin: ($gutter * -1) 0 0 ($gutter * -1);

			article {
				width: calc(#{calc(100% / 2)} - #{$gutter * 1});
				margin: $gutter 0 0 $gutter;
			}
		}

		@include breakpoint('<=small') {
			$gutter: _size(gutter) * 0.5;

			margin: ($gutter * -1) 0 0 ($gutter * -1);

			article {
				width: calc(#{calc(100% / 2)} - #{$gutter * 1});
				margin: $gutter 0 0 $gutter;

				&:hover {
					> .image {
						@include vendor('transform', 'scale(1.0)');
					}
				}
			}
		}

		@include breakpoint('<=xsmall') {
			$gutter: _size(gutter) * 0.5;

			margin: 0;

			article {
				width: 100%;
				margin: $gutter 0 0 0;
			}
		}
	}

