///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
		margin: -1em 0 _size(element-margin) -1em;

		li {
			display: inline-block;
			padding: 1em 0 0 1em;
		}
	}