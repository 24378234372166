@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'fontawesome-all.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,700,900');

/*
	Phantom by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( 361px,   480px  ),
		xxsmall:  ( null,    360px  )
	));

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/section';
	@import 'components/icon';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/form';
	@import 'components/box';
	@import 'components/image';
	@import 'components/table';
	@import 'components/button';
	@import 'components/tiles';
	@import 'components/features';
	@import 'components/email-embed';

// Layout.

	@import 'layout/header';
	@import 'layout/menu';
	@import 'layout/main';
	@import 'layout/sales';
	@import 'layout/footer';
	@import 'layout/wrapper';
