///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			margin-top: _size(element-margin) * -0.5;
		}

		@include breakpoint('<=small') {
			p {
				margin-top: 0;
			}
		}
	}