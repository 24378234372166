///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///
@use 'sass:color';

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}

		&:before {
			line-height: inherit;
		}

		&.solid {
			&:before {
				font-weight: 900;
			}
		}

		&.brands {
			&:before {
				font-family: 'Font Awesome 5 Brands';
			}
		}

		&.style1 {
		}

		&.style2 {
			@include vendor('transition', (
				'background-color #{_duration(transition)} ease-in-out',
				'color #{_duration(transition)} ease-in-out',
				'border-color #{_duration(transition)} ease-in-out'
			));
			background-color: transparent;
			border: solid 1px _palette(border);
			border-radius: _size(border-radius);
			width: 2.65em;
			height: 2.65em;
			display: inline-block;
			text-align: center;
			line-height: 2.65em;
			color: inherit;

			&:before {
				font-size: 1.1em;
			}

			&:hover {
				color: _palette(accent1);
				border-color: _palette(accent1);
			}

			&:active {
				background-color: color.adjust(_palette(accent1), $alpha: -0.9);
			}
		}
	}
