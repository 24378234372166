///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Table */

	.table-wrapper {
		-webkit-overflow-scrolling: touch;
		overflow-x: auto;
	}

	table {
		margin: 0 0 _size(element-margin) 0;
		width: 100%;

		tbody {
			tr {
				border: solid _size(border-width) _palette(border);
				border-left: 0;
				border-right: 0;

				&:nth-child(2n + 1) {
					background-color: _palette(border-bg);
				}
			}
		}

		td {
			padding: 0.75em 0.75em;
		}

		th {
			color: _palette(fg-bold);
			font-size: 0.9em;
			font-weight: _font(weight-bold);
			padding: 0 0.75em 0.75em 0.75em;
			text-align: left;
		}

		thead {
			border-bottom: solid (_size(border-width) * 2) _palette(border);
		}

		tfoot {
			border-top: solid (_size(border-width) * 2) _palette(border);
		}

		&.alt {
			border-collapse: separate;

			tbody {
				tr {
					td {
						border: solid _size(border-width) _palette(border);
						border-left-width: 0;
						border-top-width: 0;

						&:first-child {
							border-left-width: _size(border-width);
						}
					}

					&:first-child {
						td {
							border-top-width: _size(border-width);
						}
					}
				}
			}

			thead {
				border-bottom: 0;
			}

			tfoot {
				border-top: 0;
			}
		}
	}