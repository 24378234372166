///
/// Phantom by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Row */

	.row {
		@include html-grid(2em);

		@include breakpoint('<=xlarge') {
			@include html-grid(2em, 'xlarge');
		}

		@include breakpoint('<=large') {
			@include html-grid(2em, 'large');
		}

		@include breakpoint('<=medium') {
			@include html-grid(1.5em, 'medium');
		}

		@include breakpoint('<=small') {
			@include html-grid(1em, 'small');
		}

		@include breakpoint('<=xsmall') {
			@include html-grid(1em, 'xsmall');
		}
	}